import * as THREE from 'three';
import {GUI} from 'dat.gui';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {OutputPass} from 'three/examples/jsm/postprocessing/OutputPass';

// Parametri di Serena
const serenaParams = {
    'enable_voice': true,  // Usa un valore booleano invece di un numero
    'quality': 'High',
};

function LoadAiSerena()
{
const renderer = new THREE.WebGLRenderer({antialias: true});
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);
//renderer.setClearColor(0x222222);

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera(
	45,
	window.innerWidth / window.innerHeight,
	0.1,
	1000
);

const params = {
    red: 0.4,
    green: 0.17,  // Adjusted starting color values
    blue: 1.0,
    threshold: 0.15,  // Lower bloom threshold for a stronger glow
    strength: 0.2,   // Increased bloom strength for glow effect
    radius: 0.38
};

renderer.outputColorSpace = THREE.SRGBColorSpace;

const renderScene = new RenderPass(scene, camera);

const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight));
bloomPass.threshold = params.threshold;
bloomPass.strength = params.strength;
bloomPass.radius = params.radius;

const bloomComposer = new EffectComposer(renderer);
bloomComposer.addPass(renderScene);
bloomComposer.addPass(bloomPass);

const outputPass = new OutputPass();
bloomComposer.addPass(outputPass);

camera.position.set(0, -2, 16);
camera.lookAt(0, 0, 0);

const uniforms = {
	u_time: {type: 'f', value: 0.0},
	u_frequency: {type: 'f', value: 0.0},
	u_red: {type: 'f', value: 0.4},
	u_green: {type: 'f', value: 0.17},
	u_blue: {type: 'f', value: 1.0}
}

const mat = new THREE.ShaderMaterial({
	uniforms,
	vertexShader: document.getElementById('vertexshader').textContent,
	fragmentShader: document.getElementById('fragmentshader').textContent,
	transparent: true,  // Enable transparency
    depthWrite: false   // Disable depth write to correctly render transparent objects
});

const geo = new THREE.IcosahedronGeometry(4, 70);  // Increased detail level
const mesh = new THREE.Mesh(geo, mat);
scene.add(mesh);
mesh.material.wireframe = true;  // Disable wireframe for solid look

// Create the smaller sphere geometry and material
const smallSphereGeo = new THREE.IcosahedronGeometry(2.012, 20);  // Smaller radius and less detail
const smallSphereUniforms = {
    u_time: { type: 'f', value: 0.0 },
    u_frequency: { type: 'f', value: 0.0 },
    u_red: { type: 'f', value: 0.16 },  // Different color
    u_green: { type: 'f', value: 0.0 }, // Different color
    u_blue: { type: 'f', value: 0.64 }   // Different color
};

// Create a new shader material for the smaller sphere
const smallSphereMat = new THREE.ShaderMaterial({
    uniforms: smallSphereUniforms,
    vertexShader: document.getElementById('vertexshader').textContent,
    fragmentShader: document.getElementById('fragmentshader-small').textContent,
	transparent: true,  // Enable transparency
    depthWrite: false   // Disable depth write to correctly render transparent objects
});

// Create the smaller sphere mesh and add it to the scene
const smallSphereMesh = new THREE.Mesh(smallSphereGeo, smallSphereMat);
scene.add(smallSphereMesh);
smallSphereMesh.material.wireframe = false; // Optional: Set to false for solid appearance

/// SPIKESSSSS ///

// New sphere for spikes
const spikeSphereGeo = new THREE.IcosahedronGeometry(1.620, 20);  // Slightly larger radius than the small sphere
const spikeSphereUniforms = {
    u_time: { type: 'f', value: 0.0 },
    u_frequency: { type: 'f', value: 0.0 },
    u_dotMinSize: { type: 'f', value: 6.5 },  // Minimum dot size
    u_dotMaxSize: { type: 'f', value: 8.04 },  // Maximum dot size
    u_dotIntensity: { type: 'f', value: 5.0 },  // Dot intensity
    u_dotDensity: { type: 'f', value: 0.02 },  // Dot density (0.0 to 1.0)
    u_spikeMinSpeed: { type: 'f', value: 0.5 },  // Minimum spike speed (adjustable)
    u_spikeMaxSpeed: { type: 'f', value: 10.0 }  // Maximum spike speed (adjustable)
};

// Shader material for the spikes
const spikeSphereMat = new THREE.ShaderMaterial({
    uniforms: spikeSphereUniforms,
    vertexShader: document.getElementById('vertexshader-spikes').textContent,
    fragmentShader: document.getElementById('fragmentshader-spikes').textContent,  // New fragment shader for spikes
    transparent: true,  // Enable transparency to make only the spikes visible
    depthWrite: false   // Disable depth write to correctly render transparent objects
});

// Create the spike sphere mesh and add it to the scene
const spikeSphereMesh = new THREE.Mesh(spikeSphereGeo, spikeSphereMat);
scene.add(spikeSphereMesh);
spikeSphereMesh.material.wireframe = false;  // For solid look

/// END SPIKESSSS ///

const listener = new THREE.AudioListener();
camera.add(listener);

const sound = new THREE.Audio(listener);

const audioLoader = new THREE.AudioLoader();
//let ut='./assets/Beats.mp3';
const ulut='./assets/jarvis_full.wav';
audioLoader.load(ulut, function(buffer) {
	sound.setBuffer(buffer);
	//window.addEventListener('click', function() {
	//	sound.play();
	//});
});
window.sound = sound;  // Assegna la variabile sound all'oggetto window
window.audioLoader = audioLoader;

const analyser = new THREE.AudioAnalyser(sound, 256);  // Increase FFT size for smoother response

const gui = new GUI();
const serenaFolder = gui.addFolder('Serena');

serenaFolder.add(serenaParams, 'enable_voice', 0, 1).onChange(function(value) {
	serenaParams.enable_voice.value = value ? 1 : 0;
});
serenaFolder.add(serenaParams, 'quality', ['High', 'Medium', 'Low']).onChange(function(value) {
    uniforms.quality.value = (value === 'High') ? 0 : (value === 'Medium' ? 0.5 : 1);
  });
window.serenaParams = serenaParams;

/*const colorsFolder = gui.addFolder('Colors');
colorsFolder.add(params, 'red', 0, 1).onChange(function(value) {
	uniforms.u_red.value = Number(value);
});
colorsFolder.add(params, 'green', 0, 1).onChange(function(value) {
	uniforms.u_green.value = Number(value);
});
colorsFolder.add(params, 'blue', 0, 1).onChange(function(value) {
	uniforms.u_blue.value = Number(value);
});

const iColorsFolder = gui.addFolder('iColors');
iColorsFolder.add(params, 'red', 0, 1).onChange(function(value) {
	smallSphereUniforms.u_red.value = Number(value);
});
iColorsFolder.add(params, 'green', 0, 1).onChange(function(value) {
	smallSphereUniforms.u_green.value = Number(value);
});
iColorsFolder.add(params, 'blue', 0, 1).onChange(function(value) {
	smallSphereUniforms.u_blue.value = Number(value);
});

const bloomFolder = gui.addFolder('Bloom');
bloomFolder.add(params, 'threshold', 0, 1).onChange(function(value) {
	bloomPass.threshold = Number(value);
});
bloomFolder.add(params, 'strength', 0, 3).onChange(function(value) {
	bloomPass.strength = Number(value);
});
bloomFolder.add(params, 'radius', 0, 1).onChange(function(value) {
	bloomPass.radius = Number(value);
});*/

let mouseX = 0;
let mouseY = 0;
document.addEventListener('mousemove', function(e) {
	let windowHalfX = window.innerWidth / 2;
	let windowHalfY = window.innerHeight / 2;
	mouseX = (e.clientX - windowHalfX) / 100;
	mouseY = (e.clientY - windowHalfY) / 100;
});

const clock = new THREE.Clock();
function animate() {
	camera.position.x += (mouseX - camera.position.x) * .05;
	camera.position.y += (-mouseY - camera.position.y) * 0.5;
	camera.lookAt(scene.position);
	
	uniforms.u_time.value = clock.getElapsedTime();
	uniforms.u_frequency.value = analyser.getAverageFrequency();
    if (uniforms.u_frequency.value <= 0)
        uniforms.u_frequency.value = 7;
	
    smallSphereUniforms.u_time.value = uniforms.u_time.value;  // Sync time
	if (uniforms.u_frequency.value <= 0)
		smallSphereUniforms.u_frequency.value = 25;
	else
    	smallSphereUniforms.u_frequency.value = 4 + uniforms.u_frequency.value;

	spikeSphereUniforms.u_time.value = smallSphereUniforms.u_time.value;
	spikeSphereUniforms.u_frequency.value = smallSphereUniforms.u_frequency.value;

	// Set small sphere position to be inside the main sphere
    smallSphereMesh.position.set(
        mesh.position.x,  // Sync position
        mesh.position.y,
        mesh.position.z
    );

    bloomComposer.render();
	requestAnimationFrame(animate);
}
animate();

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
	bloomComposer.setSize(window.innerWidth, window.innerHeight);
});
}

// VIDEO /

function init_onWindowResize(renderer) {
    renderer.setSize(window.innerWidth, window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
  }

function init() {

	file = './assets/NeuralLeadIntroHD-2K.mp4';

    // Creazione della scena
    const scene = new THREE.Scene();

    // Creazione della videocamera
    const camera = new THREE.PerspectiveCamera(43, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 1; // Distanza della camera dal piano

	// Variabili globali per il renderer e l'animazione
	let renderer, animationId;

    // Creazione del renderer
    renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setSize(window.innerWidth, window.innerHeight);
	// Creazione del renderer con gestione della gamma
    renderer.outputEncoding = THREE.RGBAFormat; // Imposta l'encoding dell'output per una migliore resa dei colori
    renderer.setPixelRatio(window.devicePixelRatio); // Ottimizza la risoluzione per display con alta densità di pixel
    document.body.appendChild(renderer.domElement);

    // Gestisci il ridimensionamento della finestra
    window.addEventListener('resize',  function() {
        init_onWindowResize(renderer);  // Passa "myParam" alla funzione "onWindowResize"
      });

    // Caricamento del video e impostazione delle proprietà
    const video = document.createElement('video');
    video.src = './assets/NeuralLeadIntroHD-2K.mp4'; // Sostituisci con il percorso del tuo video
    video.muted = true; // Muto per rispettare le policy di autoplay
    video.loop = false; // Loop del video
    video.load();

	// Aggiunta di una callback alla fine del video
    video.addEventListener('ended', onVideoEnded, false); // Aggiungi l'evento 'ended'

    // Definizione della funzione callback alla fine del video
    function onVideoEnded() {
        console.log('Il video è terminato. Deinizializzazione del renderer e canvas.');

        window.removeEventListener('resize', init_onWindowResize);

        // Annulla l'animazione
        if (animationId) {
            cancelAnimationFrame(animationId);
        }

        // Rimuovi il canvas dal DOM
        if (renderer && renderer.domElement) {
            document.body.removeChild(renderer.domElement);
        }

        // Elimina il renderer e rilascia le risorse
        if (renderer) {
            renderer.dispose();
        }

        // Pulizia della texture del video
        if (videoTexture) {
            videoTexture.dispose();
        }

        // Pulizia del materiale e della geometria
        if (plane) {
            plane.geometry.dispose();
            plane.material.dispose();
        }

        // Pulizia della scena
        scene.clear();

		LoadAiSerena();
		$('#iPut').show();
		$("#TwS").show();
    }

    // Prova a riprodurre il video in modo asincrono
    /*video.play().catch((error) => {
        console.log("Autoplay non consentito. Il video verrà avviato al clic dell'utente.");
    });*/

    // Creazione di una texture video
    const videoTexture = new THREE.VideoTexture(video);

    // Creazione del materiale utilizzando la texture del video
    const videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture });

    // Creazione di un piano per visualizzare il video
    const planeGeometry = new THREE.PlaneGeometry(16, 9);
    const plane = new THREE.Mesh(planeGeometry, videoMaterial);
    plane.scale.set(0.1, 0.1, 0.1); // Ridimensionamento del piano per adattarsi alla vista

    scene.add(plane);

    // Funzione di rendering
    function animate() {
		animationId = requestAnimationFrame(animate);
        renderer.render(scene, camera);
    }

    animate();

	// Aggiungi un pulsante per richiedere l'interazione dell'utente
    const playButton = document.createElement('button');
    playButton.textContent = 'Open Website';
    playButton.style.position = 'absolute';
    playButton.style.top = '50%';
    playButton.style.left = '50%';
    playButton.style.transform = 'translate(-50%, -50%)';
    playButton.style.padding = '10px 20px';
    playButton.style.fontSize = '20px';
    playButton.style.backgroundColor = "#821f82";
    playButton.style.borderColor = "#52166d";
    playButton.style.color = "#f0f0f0";
    playButton.style.borderRadius = "0.5em";
    document.body.appendChild(playButton);

	const divLogo = document.createElement('div');
    divLogo.style.position = 'absolute';
	divLogo.style.bottom = '0%';
	divLogo.style.left = '50%';
	divLogo.style.transform = 'translate(-50%, 0%)';
	divLogo.style.padding = '0px';

	const logo = document.createElement('img');
    logo.src = 'https://www.neurallead.com/assets/images/neurallead/logo.png';
    logo.style.width = '256px';
	logo.style.margin = "0px auto";
	logo.style.marginLeft = "margin-left: -16px";
	logo.style.position = "absolute";
	logo.style.bottom = "15px";
	logo.style.zIndex = "3";
    //divLogo.appendChild(logo);

	const logoText = document.createElement('p');
	logoText.textContent = "NeuralLead";
	logoText.style.margin = "0px auto";
	logoText.style.marginBottom = "0px";
	logoText.style.color = "rgb(170, 94, 204)";
	logoText.style.fontSize = "21pt";
	logoText.style.zIndex = "5";
	logoText.style.position = "relative";
	logoText.style.backgroundColor = "rgba(0, 0, 0, 0.6)";
	logoText.style.marginBottom = "10px";
	logoText.style.padding = "0.41em 0.83em";
	logoText.style.textAlign = "center";
	divLogo.appendChild(logoText);
	
    document.body.appendChild(divLogo);

    // Aggiungi un listener di clic per avviare il video
    playButton.addEventListener('click', () => {
        video.muted = false; // Attiva l'audio del video
        video.play().then(() => {
            console.log('Video in riproduzione con audio.');
            //document.body.removeChild(playButton); // Rimuove il pulsante dopo che il video è stato avviato
            //document.body.removeChild(divLogo); // Rimuove il pulsante dopo che il video è stato avviato
			$(playButton).fadeOut(1000);
			$(divLogo).fadeOut(1000);
        }).catch((error) => {
            console.error('Errore durante la riproduzione del video:', error);
        });
    });
}

init();
